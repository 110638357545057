import React from 'react'
import { truncateString } from '../../../util/helperFunctions';

const HostDetails = ({ userInfo }) => {
  return (
    <div className="flex flex-row space-x-1 items-center justify-between text-mute_text font-medium md:text-sm text-xs">
      <span className="flex flex-row items-center justify-start">
          {truncateString(userInfo[0]?.fName, 18)}
      </span>
      <span className="flex flex-row items-center justify-start">
          | {userInfo[0]?.karma} Karma
      </span>
    </div>
  )
}

export default HostDetails;