import React from 'react';
import moment from 'moment';
import TIMINGS from "../../../util/timingsMap";
import { formatDateForActivity } from '../../../util/formatDate';

const TimeDetails = ({ startTime, endTime, timing, date }) => {

  const formattedDay = formatDateForActivity(date);

  return (
    <div className="w-full flex flex-row items-center space-x-1 justify-between">
      <span className="flex flex-row mt-2 items-center justify-start text-on_background font-semibold text-xs md:text-sm">
          <span className="mr-1"> {formattedDay},</span>
          {startTime
            ? `${moment(startTime).format("hh:mm A")} ${endTime?` - ${moment(
                  endTime
              ).format("hh:mm A")}`:""}`
            : `${TIMINGS[timing]}`}
      </span>
    </div>
  )
}

export default TimeDetails;
