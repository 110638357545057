import React from "react";
import SkillLevel from "../../Common/SkillLevel";
import GameSkill from "../../Common/GameSkill";

const SkillLabel = ({ minLevel, maxLevel, isStrict = false }) => {

  if (minLevel === maxLevel) {
     return <SkillLevel level={minLevel} />;
  }
  return <GameSkill minLevel={minLevel} maxLevel={maxLevel} isStrict={isStrict} />;
}

export default SkillLabel;
