import Image from 'next/image';
import React from 'react';

const Joinee = ({ maxPlayers, joineeCount }) => {

  if (maxPlayers !== -1) {
    if (joineeCount === maxPlayers) {
      return (
        <Image src="https://playo-website.gumlet.io/playo-website-v3/match_full.png" height={16} width={80} alt='Match full for activity' />
      );
    }

    const slotsLeft = maxPlayers - joineeCount;

    if (slotsLeft <= 2) {
      return (
        <div className='border border-[#FFC400] rounded-lg bg-[#FFF3CC] px-1 py-[2px] font-medium text-xs leading-[18px]'>
          🚀 Only {slotsLeft} Slots
        </div>
      );
    }

    return (
      <div className='font-bold'>
        {joineeCount}
        <span className='text-xs font-medium'>/{maxPlayers}</span> {" "}
        Going
      </div>
    )
  }

  return (
    <div className='font-bold'>
      {joineeCount}{" "} Going
    </div>
  );
};

const JoineeDetails = ({ type, currencyTxt, price, userInfo, maxPlayers, joineeCount }) => {
  const isMaxFull = joineeCount === maxPlayers || false;
  return (
    <div className='w-full flex flex-row items-center space-x-1 justify-between'>
      <div className='w-full flex-row  flex justify-between items-center'>
        <div className='flex flex-row w-full items-center'>
          <div className='flex items-center'>
            {userInfo.slice(0, 4).map((element, index) => (
              <div
                key={index}
                className={`${
                  index > 0 ? "h-8 w-8 border-[4px] border-[white]" : "h-10 w-10"
                } rounded-full mr-[-14px] overflow-hidden`}
              >
                <img
                  src={element.profilePicUrl}
                  alt="profile pic"
                  className={`w-full h-full rounded-full object-center object-cover ${isMaxFull ? 'grayscale' : ''}`}
                />
              </div>
            ))}
          </div>

          <span className="text-sm font-medium border-2 rounded-full ml-4 mr-1 text-gray-800"></span>
          
          <Joinee maxPlayers={maxPlayers} joineeCount={joineeCount} />
        </div>
      </div>

      {type === 1 && (
        <div className="flex-row ml-auto min-w-[80px] space-x-1 flex justify-end items-center bg-surface rounded-lg">
          <div className=" flex-row space-x-1 flex items-center justify-center">
              <Image
                height={24}
                width={24}
                src="https://playo-website.gumlet.io/playo-website-v2/web-home-screen/pay-join-icon.png"
              />
              <span className="font-semibold text-[14px] flex flex-row w-full">
                {currencyTxt} {price.toFixed(0)}
              </span>
          </div>
        </div>
      )}
    </div>
  )
}

export default JoineeDetails;
