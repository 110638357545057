export const SKILL_LEVEL = {
  0: {
    text: 'No Skill'
  },
  1: {
    text: 'Beginner',
    color: '#baf1a1'
  },
  2: {
    text: 'Amateur',
    color: '#43d8c9'
  },
  3: {
    text: 'Intermediate',
    color: '#f3c623'
  },
  4: {
    text: 'Advance',
    color: '#d8b9c3'
  },
  5: {
    text: 'Professional',
    color: '#ff9d76'
  }
}

export const SKILL_LABELS = [
  "No Skill",
  "Beginner",
  "Amateur",
  "Intermediate",
  "Advanced",
  "Professional"
];

export default {};
