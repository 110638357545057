import React from 'react';
import { useRouter } from 'next/router';
import LabelItems from './LabelItems';
import JoineeDetails from './JoineeDetails';
import Image from 'next/image';
import { truncateString } from '../../../util/helperFunctions';
import HostDetails from './HostDetails';
import TimeDetails from './TimeDetails';
import SkillLabel from './SkillLevel';

const ActivityCardV2 = ({ sportIcon, activity, classes = 'max-w-[340px]  w-full min-w-[320px]' }) => {

  const router = useRouter();

  const handleClick = () => {
    router.push(`/match/${activity.id}`);
  }

  return (
    <div
      className={`flex overflow-hidden relative h-[250px] flex-col space-y-2 items-start p-4 justify-start shadow-card border mb-6 rounded-[16px] border-[#E3E8E6] cursor-pointer bg-white ${classes}`}
      onClick={handleClick}
    >

      {/* Label and Mode for Activity */}
      <LabelItems
        activityType={activity?.activityType}
        sportsPlayingMode={activity?.sportsPlayingMode}
        shortListed={activity?.shortListed}
        isPlaypalPlaying={activity?.isPlaypalPlaying}
      />
      
      {/* Joinee Details */}
      <JoineeDetails
        type={activity?.type}
        currencyTxt={activity?.currencyTxt}
        price={activity?.price}
        userInfo={activity?.userInfo}
        maxPlayers={activity?.maxPlayers}
        joineeCount={activity?.joineeCount}
      />

      {/* Host details */}
      <HostDetails userInfo={activity?.userInfo} />

      {/* Time Details foe activity */}
      <TimeDetails startTime={activity?.startTime} endTime={activity?.endTime} date={activity?.date} timing={activity?.timing} />

      {/* Venue address section */}
      <div className="flex-row w-full flex justify-start items-center">
        <div className=" flex-row mt-1 space-x-2 flex items-center">
          <div className="px-1 flex flex-row items-center h-[22px] w-[22px]">
            <Image
              height={22}
              width={18}
              src="https://playo-website.gumlet.io/playo-website-v2/web-home-screen/act-card-loc-icon.png"
              alt={activity?.location || 'location'}
            />
          </div>
          <span className="font-normal md:text-sm text-xs leading-[18px] flex flex-row text-on_background w-full">
            {truncateString(activity?.location || "", 20)}{" "}
            ~{activity?.distance.toFixed(2)} Kms
          </span>
        </div>
        <div></div>
      </div>

      {/* Sport and proficiency details */}
      <div className="flex flex-row items-center w-full pt-2 justify-start">
        <Image src={sportIcon.v2GrayIcon} alt="sport-icon" height={22} width={22} />
        <SkillLabel minLevel={activity.minSkill} maxLevel={activity.maxSkill} isStrict={activity.strictSkill} />
        {activity?.booking && (
            <div className="flex -right-1 px-2 md:text-sm text-xs leading-[18px] tracking-wider absolute rounded-md  bg-primary text-white font-normal ">
              BOOKED
            </div>
        )}
      </div>
    </div>
  )
}

export default ActivityCardV2;
