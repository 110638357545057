import React from 'react'

const LabelItems = ({ activityType, sportsPlayingMode, shortListed, isPlaypalPlaying }) => {
  return (
    <div className="flex flex-row items-center justify-start w-full font-medium py-[2px]">
      {sportsPlayingMode?.name ? <span className="md:text-sm text-xs leading-[18px] text-mute_text ml-1 capitalize">
          {sportsPlayingMode?.name}
      </span> : null}

      {(activityType && sportsPlayingMode?.name) ? (
          <span className="md:text-sm text-xs leading-[18px] border-2 rounded-full text-gray-800 ml-1"></span>
      ) : null}

      {activityType ? <span className="md:text-sm text-xs leading-[18px] text-mute_text ml-1 capitalize">
          {activityType}
      </span> : null}

      {isPlaypalPlaying ? <span className='bg-[#CCF0E0] text-[#1ABC72] ml-auto rounded-3xl px-2 py-1 text-xs'>
        Playing
      </span> : null}

      {shortListed ? <img src='https://playo-website.gumlet.io/playo-website-v3/icons/bookmark.png' width={12} height={16} alt='Bookmarked' className='ml-3' /> : null}
    </div>
  )
}

export default LabelItems;
