import React from 'react';
import { SKILL_LEVEL } from '../../constants/skillConstants';

const SkillLevel = ({ level }) => {

  const skill = level ? SKILL_LEVEL[level] : null;

  if (!skill) return null;

  return (
    <div className={`border relative bg-white border-${skill.text.toLowerCase()} rounded-lg w-40 h-full ml-2 overflow-hidden`}>
      <div className='absolute w-full flex items-center justify-center h-full font-medium text-xs'>{skill.text}</div>
      <div className={`bg-${skill.text.toLowerCase()} w-2/3 rounded-lg h-full`} />
    </div>
  )
};

export default SkillLevel;
