const formatDate = ({
        isoString, 
        dayType=undefined, 
        monthType=undefined, 
        yearType=undefined
    }) => {
    const options = { 
        day: dayType,
        month: monthType,
        year: yearType
    };
    return new Date(isoString).toLocaleDateString(undefined, options);
};

const formatTime = (isoString) => {
    if (!isoString) return "TBD";

    return new Date(isoString).toLocaleString(
        "en-US", {hour: "numeric", hour12: true}
    );
};

const dayOfWeekNamesShort = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
const monthNamesLong = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

const formatDateForActivity = (date) => {
    const today = new Date();
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);

    const selectedDate = new Date(date);

    if (+selectedDate === +today) {
        return "Today";
    } else if (+selectedDate === +tomorrow) {
        return "Tomorrow";
    } else {
        return ` ${dayOfWeekNamesShort[selectedDate.getDay()]}, ${selectedDate.getDate().toString().padStart(2, "0")} ${monthNamesLong[selectedDate.getMonth()]} ${selectedDate.getFullYear()}`;
    }
}

export { formatDate, formatTime, formatDateForActivity };